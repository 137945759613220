import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer2 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.mezoterapiaMikroiglowa}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Mezoterapia mikroigłowa
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="mezoterapia mikroiglowaideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania</Subtitle>
            <Paragraph>
              Z wiekiem skóra traci jędrność i nawilżenie. Przyczyną jest utrata
              włókien kolagenowych. Urządzenie do mezoterapii mikroigłowej
              pozwala na wprowadzanie substancji czynnych w głąb skóry, a swoje
              działanie opiera na zasadzie automatycznego nakłuwania skóry przy
              pomocy pulsujących igieł. Poprzez mikronakłucia fibroblasty w
              skórze są pobudzane do produkcji nowych włókien kolagenowych,
              dzięki czemu skóra staje się bardziej elastyczna i sprężysta.
            </Paragraph>
            <Paragraph noMarginBottom>W efekcie uzyskujemy: </Paragraph>
            <List>
              <ListItem>Wygładzenie płytkich zmarszczek</ListItem>
              <ListItem>Zwiększenie jędrności i gęstości skóry</ListItem>
              <ListItem>Znaczną poprawę nawilżenia</ListItem>
              <ListItem>Młodzieńczy i świeży wygląd</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu</Subtitle>
            <Paragraph>
              Zabieg mezoterapii zawsze poprzedzony jest indywidualną
              konsultacją lekarską. Polega on na wykonaniu serii mikronakłuć
              skóry urządzeniem wyposażonym w bardzo małe i cienkie igiełki i
              podaniu koktajlu złożonego z kwasu hialuronowego, aminokwasów,
              witamin. Przed zabiegiem skórę znieczula się przy pomocy
              specjalnego kremu.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty</Subtitle>
            <Paragraph>
              Dla uzyskania najlepszych efektów zabiegi mezoterapii mikroigłowej
              wykonujemy w serii 3-6 zabiegów co 3-4 tygodnie. Pierwsze efekty
              widoczne są już po kilku dniach, natomiast po upływie około 3
              miesięcy widać już bardziej trwały efekt.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Cena</Subtitle>
            <Paragraph>
              Cena zabiegu zaczyna się już od 400 zł. Przy pierwszej wizycie
              odbierz swoją kartę lojalnościową i otrzymaj -30% na czwarty
              zabieg medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>
                Aktywne i niewyrównane choroby autoimmunologiczne
              </ListItem>
              <ListItem>Skóra naczynkowa</ListItem>
              <ListItem>Trądzik różowaty</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer2;

export const Head = () => <SEO tags={TAGS.mezoterapia} />;
